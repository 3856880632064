import React from "react";
import { Header } from "./components/header/Header";
import { Studio } from "./components/studio/Studio";

import './App.css';

export const App = () => {

  return (
    <>
      <Header />
      <Studio />
    </>
  )
}