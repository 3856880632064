import React from 'react';

import './Sideview.css';

export const Sideview = () => {
  return (
    <div id='tools' style={{
      padding: '10px',
      background: '#ffffff'
    }}>Tools & statistics</div>
  )
}
